/* eslint-disable semi */
/* eslint-disable quotes */
/* <![CDATA[ */

(function () {
  var sz = document.createElement("script");
  sz.type = "text/javascript";
  sz.async = true;

  sz.src = "https://siteimproveanalytics.com/js/siteanalyze_6123065.js";

  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(sz, s);
})();

/* ]]> */
